
import CrmContactBadge from "./ContactBadge.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CrmContactPreview",
  components: { CrmContactBadge },
  props: {
    contact: {
      type: Object,
      default: () => null,
    },
    isLinked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["linkToContact", "navigateToMobiCRM"],
  computed: {
    /**
     * Gets the contact information
     * @returns {string} the information
     */
    contactInfo(): string {
      let info = this.contact.position;
      if (info && this.contact.companyName) {
        info += " " + this.$t("ContactPreview.AtLabel") + " " + this.contact.companyName;
      } else {
        info = this.contact.companyName;
      }
      return info;
    },
  },
});
