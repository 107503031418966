
import accountsApi from "../../api/account/accountsApi";
import accountDetailsApi from "../../api/account/accountDetailsApi";
import { defineComponent } from "vue";
import AccountLabel from "../../model/AccountLabel";

export default defineComponent({
  name: "CrmAccountSelect",

  props: {
    modelValue: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    accountType: {
      type: Number,
      default: null,
    },
  },

  emits: ["update:modelValue", "searchTextChanged", "change"],

  /**
   * Gets the component data
   * @returns {object} Component data
   */
  data() {
    return {
      accountId: null,
      accounts: [] as AccountLabel[],
      isLoading: false,
      isFetchingAccount: false,
    };
  },

  computed: {
    selectedAccountId: {
      /**
       * Gets the account id
       * @returns {string}
       */
      get(): string {
        if (this.isFetchingAccount) {
          return this.$t("AccountSelect.LoadingText");
        }
        return this.modelValue ?? "";
      },

      /**
       * On option selected
       * @param {string} value - selected account id
       */
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
  },

  watch: {
    /**
     * When the prop value changes
     * @param {string} newValue - new value
     * @returns {Promise}
     */
    modelValue: async function (newValue) {
      await this.getAccount(newValue);
    },
  },

  /**
   * When component is mounted
   * @returns {Promise}
   */
  async mounted() {
    await this.getAccount(this.modelValue ?? "");
  },

  methods: {
    /**
     * Fills the html options with the corresponding accounts on the backend
     * @param {string} searchText - search text
     * @returns {Promise}
     */
    async searchAccounts(searchText: string) {
      await this.searchTextChanged(searchText);
      if (searchText !== "") {
        await this.getAccounts(searchText);
      } else {
        this.accounts = [];
      }
    },

    /**
     * When fetching text changed
     * @param {string} newValue - Search text
     */
    async searchTextChanged(newValue: string) {
      this.$emit("searchTextChanged", newValue);
    },

    /**
     * Fetches the accounts from the backend
     * @param {string} searchText - search text
     * @returns {Promise}
     */
    async getAccounts(searchText: string) {
      this.isLoading = true;
      let result = await accountsApi.quicksearch(searchText, this.accountType);
      this.isLoading = false;

      if (result.isSuccess) {
        this.accounts = result.data;
      }
    },

    /**
     * Fetches the specific account from the backend
     * @param {string} accountId - Account id
     * @returns {Promise}
     */
    async getAccount(accountId: string) {
      if (!accountId) {
        return;
      }

      this.isFetchingAccount = true;
      const result = await accountDetailsApi.getAccountLabel(accountId, true);

      this.isFetchingAccount = false;

      if (result.isSuccess) {
        this.accounts = [result.data] as AccountLabel[];
      }
    },

    /**
     * When clearing account selection
     */
    onClear() {
      this.accounts = [];
    },

    /**
     * Handles selection changed
     * @param {string} value - the selected value
     */
    selectionChanged(value: string) {
      this.$emit("change", value);
    },
  },
});
