
import { defineComponent, ref, watch } from "vue";
import CrmNavbar from "./Navbar.vue";
import CrmContactCreation from "./ContactCreation.vue";
import CrmAccountCreation from "./AccountCreation.vue";
import { useRouter } from "../router";
import { useOfficeState } from "../store/officeState";
import NewAccount from "../model/NewAccount";
import NewContact from "../model/NewContact";
import PhoneHelper from "../helpers/phoneHelper";
import contactsApi from "../api/contact/contactsApi";
import accountsApi from "../api/account/accountsApi";

export default defineComponent({
  name: "CrmEntityCreation",
  components: { CrmAccountCreation, CrmNavbar, CrmContactCreation },

  props: {
    emailAddress: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    isSender: {
      type: [Boolean, String],
      default: false,
    },
  },

  setup() {
    const { mailboxItem } = useOfficeState();
    const activeTabName = ref("contact");
    let isCreatingContact = ref(false);
    let accountTabVisible = ref(false);
    const contactCreationComponent = ref<InstanceType<typeof CrmContactCreation>>();
    const accountCreationComponent = ref<InstanceType<typeof CrmAccountCreation>>();

    /**
     * Watch Outlook mailbox item changed
     */
    watch(mailboxItem, () => {
      useRouter().push({
        name: "email",
      });
    });

    /**
     * Shows the account tab visibility
     */
    function showAccountTab() {
      accountTabVisible.value = true;
      activeTabName.value = "account";
    }

    /**
     * Updates the account tab visibility
     * @param contactAccountId
     */
    function updateAccountTabVisibility(contactAccountId: string) {
      accountTabVisible.value &&= !contactAccountId;
    }

    /**
     * Creates the new Contact
     */
    async function createContact() {
      if (contactCreationComponent.value?.isFormValid()) {
        isCreatingContact.value = true;
        let contactForm = contactCreationComponent.value?.form as NewContact;

        // Account creation
        let accountForm = accountCreationComponent.value?.form as NewAccount;
        if (accountForm) {
          accountForm.phone = PhoneHelper.parse(accountForm.phone);
          accountForm.mobile = PhoneHelper.parse(accountForm.mobile);
          if (!contactForm.accountId && accountForm.companyName && accountTabVisible.value) {
            const accountCreationResult = await accountsApi.createAccount(accountForm);
            if (accountCreationResult.isSuccess) {
              contactForm.accountId = accountCreationResult.data;
            }
          }
        }

        // Contact creation
        contactForm.phone = PhoneHelper.parse(contactForm.phone);
        contactForm.mobile = PhoneHelper.parse(contactForm.mobile);
        const contactCreationResult = await contactsApi.createContact(contactForm);
        if (contactCreationResult.isSuccess) {
          useRouter().go(-1);
        }

        isCreatingContact.value = false;
      } else {
        return false;
      }
    }

    return {
      activeTabName,
      createContact,
      isCreatingContact,
      contactCreationComponent,
      accountCreationComponent,
      showAccountTab,
      updateAccountTabVisibility,
      accountTabVisible,
    };
  },
});
