import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c30cb6b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "navbar",
  role: "navigation"
}
const _hoisted_2 = { class: "navbar-content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "navbar-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_crm_sidebar = _resolveComponent("crm-sidebar")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_crm_sidebar, {
      visible: _ctx.isMenuVisible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isMenuVisible) = $event))
    }, null, 8, ["visible"]),
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        (_ctx.navigateBackVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("i", {
                class: "far fa-chevron-left burger-menu-icon",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.navigateBack && _ctx.navigateBack(...args)))
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("i", {
                class: _normalizeClass(["far fa-bars burger-menu-icon", { 'burger-menu-animation': !_ctx.hasMenuBeenClicked }]),
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.displayMenu && _ctx.displayMenu(...args)))
              }, null, 2)
            ])),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.title), 1)
      ])
    ])
  ]))
}