
import { defineComponent, onMounted, ref, toRef, watch, computed } from "vue";
import { useRouter } from "../router";
import contactsApi from "../api/contact/contactsApi";
import NewContact from "../model/NewContact";
import { ContactTitle } from "../model/ContactTitle";
import CrmAccountSelect from "../components/Account/AccountSelect.vue";
import CrmReferenceDataSelect from "../components/Common/ReferenceDataSelect.vue";
import CrmUserSelect from "../components/Common/UserSelect.vue";
import StringHelper from "../helpers/stringHelper";
import accountsApi from "../api/account/accountsApi";
import accountDetailsApi from "../api/account/accountDetailsApi";
import { requiredRule, emailRule, phoneRule } from "../helpers/validationRules";
import { ElForm } from "element-plus";
import { useOfficeState } from "../store/officeState";
import entityCreation from "./entityCreation";
import oidcManager from "../authentication/oidcManager";
import appSettings from "../config/appSettings";
import AccountAddress from "../model/AccountAddress";
import { PermissionItem, PermissionAction } from "../model/Permissions";
import PermissionHelper from "../helpers/PermissionHelper";

export default defineComponent({
  name: "CrmContactCreation",
  components: { CrmAccountSelect, CrmReferenceDataSelect, CrmUserSelect },
  props: {
    emailAddress: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    isSender: {
      type: Boolean,
      default: false,
    },
    accountVisible: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["contactAccountChanged", "createNewAccount"],

  setup(props, context) {
    const { getPhoneNumbersFromBody, searchOtherPhones, searchMobilePhones } = entityCreation();
    let form = ref({} as NewContact);
    let titles = ContactTitle;
    let positions = ref([]);
    let personae = ref([]);
    let rules = {
      lastName: [requiredRule()],
      email: [emailRule()],
      phone: [phoneRule()],
      mobile: [phoneRule()],
    };
    const { mailboxItem } = useOfficeState();
    const emailAddress = toRef(props, "emailAddress");
    const name = toRef(props, "name");
    const isSender = toRef(props, "isSender");
    const formComponent = ref(null);

    const hasAccountCreationPermission = computed(() =>
      PermissionHelper.hasPermission({ item: PermissionItem.ACCOUNT, action: PermissionAction.CREATE, userId: null })
    );

    /**
     * Watch Outlook mailbox item changed
     */
    watch(mailboxItem, () => {
      useRouter().push({
        name: "email",
      });
    });

    /**
     * When component is mounted
     */
    onMounted(async () => {
      await initDefaultValues();
      await getPhoneNumbersFromBody(form.value, isSender.value);
    });

    /**
     * Initializes default values
     */
    async function initDefaultValues() {
      // Name
      const namesArray = name.value?.split(" ");
      if (namesArray) {
        if (namesArray.length > 1) {
          form.value.firstName = namesArray.shift() ?? "";
          form.value.lastName = namesArray.join(" ");
        } else if (namesArray.length == 1) {
          form.value.lastName = namesArray.shift() ?? "";
        }
      }

      // email
      form.value.email = emailAddress.value;

      const refDataResult = await contactsApi.getReferenceData();
      if (refDataResult.isSuccess) {
        positions.value = refDataResult.data.positions;
        personae.value = refDataResult.data.personae;
      }

      // Manager
      form.value.contactManagerId = oidcManager.userProfile?.sub;

      // Account
      const companyName = StringHelper.getCompanyNameFromEmail(emailAddress.value);
      let searchResult = await accountsApi.quicksearch(companyName, -1);
      if (searchResult.isSuccess && searchResult.data && searchResult.data.length > 0) {
        form.value.accountId = searchResult.data[0].id;
      } else {
        form.value.accountId = localStorage.getItem(appSettings.LAST_ACCOUNT_ID_STORAGE_KEY) ?? "";
      }
      context.emit("contactAccountChanged", form.value.accountId);
    }

    /**
     * Searches the positions
     * @param {string} queryString - the search string
     * @param {Function} callback - callback function
     */
    async function searchPositions(queryString: string, callback: (suggestions: any) => []) {
      if (queryString) {
        const results = positions.value
          .filter((p: any) => p.label.toLowerCase().indexOf(queryString.toLowerCase()) !== -1)
          .map((p: any) => {
            return {
              value: p.label,
            };
          });
        callback(results);
      } else {
        callback([]);
      }
    }

    /**
     * Sets contact address from account
     * @param {string} accountId - the account id
     */
    async function SetAddressFromAccount(accountId: string) {
      let result = await accountDetailsApi.getAddresses(accountId);
      if (result.isSuccess) {
        let accountAddresses = result.data as AccountAddress[];
        let favoriteAddress = accountAddresses.find((aa) => aa.isFavorite);
        if (favoriteAddress) {
          form.value.address = `${favoriteAddress.streetLine1 ?? ""} ${favoriteAddress.streetLine2 ?? ""}\n${
            favoriteAddress.zipCode ?? ""
          } ${favoriteAddress.city ?? ""}\n${favoriteAddress.country ?? ""}`.trim();
        }
      }
    }

    /**
     * Account changed event
     * @param newAccountId
     */
    async function accountChanged(newAccountId: string) {
      if (newAccountId) {
        localStorage.setItem(appSettings.LAST_ACCOUNT_ID_STORAGE_KEY, newAccountId);
        await SetAddressFromAccount(newAccountId);
      }
      context.emit("contactAccountChanged", newAccountId);
    }

    /**
     * Creates new account
     */
    function createNewAccount() {
      form.value.accountId = "";
      context.emit("createNewAccount");
    }

    /**
     * Checks if form is valid
     * @returns {boolean} True if the form is valid otherwise false
     */
    async function isFormValid() {
      let isValid = false;
      await (formComponent.value as typeof ElForm).validate((valid: boolean) => {
        isValid = valid;
      });

      return isValid;
    }

    return {
      form,
      titles,
      positions,
      rules,
      searchPositions,
      initDefaultValues,
      formComponent,
      accountChanged,
      isFormValid,
      getPhoneNumbersFromBody,
      searchOtherPhones,
      searchMobilePhones,
      createNewAccount,
      personae,
      hasAccountCreationPermission,
    };
  },
});
