
import PictureHelper from "../../helpers/pictureHelper";
import ContactPreview from "../../model/ContactPreview";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "CrmContactBadge",
  props: {
    contact: {
      type: Object as PropType<ContactPreview>,
      default: null,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  /**
   * Gets the component data
   * @returns {object} Component data
   */
  data() {
    return {
      isImageError: false,
    };
  },
  computed: {
    /**
     * Gets the initials from first name and last name
     * @returns {string} the initials
     */
    initials(): string {
      return (
        (this.contact.firstName ? this.contact.firstName[0] : "") +
        (this.contact.lastName ? this.contact.lastName[0] : "")
      );
    },
    /**
     * Gets the photo uri of the contact
     * @returns {string}
     */
    photoUri(): string | null {
      return PictureHelper.getContactPhotoUri(this.contact);
    },
  },
  methods: {
    /**
     * When photo image loading fails
     */
    handleImageError() {
      this.isImageError = true;
    },
  },
});
