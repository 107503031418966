
import { defineComponent } from "vue";
import frLocale from "element-plus/lib/locale/lang/fr";
import enLocale from "element-plus/lib/locale/lang/en";

/* global Office */

export default defineComponent({
  name: "App",
  setup() {
    const locale = Office.context.displayLanguage.startsWith("fr") ? frLocale : enLocale;
    return {
      locale: locale,
    };
  },
});
