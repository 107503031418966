import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_crm_navbar = _resolveComponent("crm-navbar")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_crm_contact_creation = _resolveComponent("crm-contact-creation")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_crm_account_creation = _resolveComponent("crm-account-creation")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_footer = _resolveComponent("el-footer")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_header, { height: "40px" }, {
        default: _withCtx(() => [
          _createVNode(_component_crm_navbar, {
            title: _ctx.$t('EntityCreation.NavigationTitle'),
            "navigate-back-visible": true
          }, null, 8, ["title"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_tabs, {
            modelValue: _ctx.activeTabName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTabName) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tab_pane, {
                label: _ctx.$t('EntityCreation.ContactTab'),
                name: "contact"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_crm_contact_creation, {
                    ref: "contactCreationComponent",
                    name: _ctx.name,
                    "email-address": _ctx.emailAddress,
                    "is-sender": _ctx.isSender === 'true',
                    onContactAccountChanged: _ctx.updateAccountTabVisibility,
                    onCreateNewAccount: _ctx.showAccountTab
                  }, null, 8, ["name", "email-address", "is-sender", "onContactAccountChanged", "onCreateNewAccount"])
                ]),
                _: 1
              }, 8, ["label"]),
              (_ctx.accountTabVisible)
                ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                    key: 0,
                    label: _ctx.$t('EntityCreation.AccountTab'),
                    name: "account"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_crm_account_creation, {
                        ref: "accountCreationComponent",
                        name: _ctx.name,
                        "email-address": _ctx.emailAddress,
                        "is-sender": _ctx.isSender === 'true'
                      }, null, 8, ["name", "email-address", "is-sender"])
                    ]),
                    _: 1
                  }, 8, ["label"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "primary",
            icon: "el-icon-plus",
            loading: _ctx.isCreatingContact,
            disabled: _ctx.isCreatingContact,
            onClick: _ctx.createContact
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("EntityCreation.SaveButton")), 1)
            ]),
            _: 1
          }, 8, ["loading", "disabled", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}