
import { defineComponent, ref } from "vue";
import CrmSidebar from "./Sidebar.vue";
import { useRouter } from "../router";
import appSettings from "../config/appSettings";

export default defineComponent({
  name: "CrmNavbar",
  components: { CrmSidebar },
  props: {
    title: {
      type: String,
      default: "",
    },
    navigateBackVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const isMenuVisible = ref(false);
    const hasMenuBeenClicked = localStorage.getItem(appSettings.HAS_MENU_BEEN_CLICKED) === "true";

    function displayMenu() {
      isMenuVisible.value = true;
      if (!hasMenuBeenClicked) {
        localStorage.setItem(appSettings.HAS_MENU_BEEN_CLICKED, "true");
      }
    }

    return { isMenuVisible, hasMenuBeenClicked, displayMenu };
  },
  methods: {
    /**
     * Navigates back
     */
    navigateBack() {
      useRouter().go(-1);
    },
  },
});
