
import { defineComponent } from "vue";
import leadApi from "../../api/lead/leadsApi";
import CrmUserBadge from "../User/UserBadge.vue";
export default defineComponent({
  name: "CrmLeadPreview",
  components: {
    CrmUserBadge,
  },
  props: {
    lead: {
      type: Object,
      default: () => null,
    },
    isLinked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["linkToLead", "navigateToMobiCRM"],
  /**
   * Gets the component data
   * @returns {object} Component data
   */
  data() {
    return {
      leadReferenceData: null,
    };
  },
  /**
   * When component is created
   */
  async created() {
    await this.loadLeadReferenceData();
  },
  methods: {
    /**
     * Loads the lead reference data
     * @return {Promise<void>}
     */
    async loadLeadReferenceData() {
      const refDataResult = await leadApi.getReferenceData();
      if (refDataResult.isSuccess) {
        this.leadReferenceData = refDataResult.data;
      }
    },
  },
});
