import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.contact.fileName && !_ctx.isImageError)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["crm-badge-photo", { 'crm-badge-photo--large': _ctx.large }])
        }, [
          _createElementVNode("img", {
            alt: "photo",
            src: _ctx.photoUri,
            onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleImageError && _ctx.handleImageError(...args)))
          }, null, 40, _hoisted_1)
        ], 2))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["crm-badge-text", { 'crm-badge-text--large': _ctx.large }])
        }, _toDisplayString(_ctx.initials), 3))
  ]))
}