
import licenseApi from "../api/license/licenseApi";
import { defineComponent, onMounted, ref, watch, computed } from "vue";
import CrmNavbar from "./Navbar.vue";
import { useOfficeState } from "../store/officeState";
import { licenseLevel } from "../model/LicenseLevel";
import { debounce } from "lodash";
import accountsApi from "../api/account/accountsApi";
import AccountPreview from "../model/AccountPreview";
import CrmAccountPreview from "../components/Account/AccountPreview.vue";
import { useEmailService } from "../services/emailService";
import emailMessagesApi from "../api/emailMessage/emailMessagesApi";

/* global Office */

export default defineComponent({
  name: "CrmAccountEmail",
  components: { CrmNavbar, CrmAccountPreview },
  setup() {
    const searchText = ref("" as string);
    const isLoading = ref(false as boolean);
    const { mailboxItem } = useOfficeState();
    const isLicenseLevelValid = ref(true);
    const accountsList = ref([] as AccountPreview[]);
    const { associateEmailToAccount } = useEmailService();
    const associatedAccounts = ref([] as AccountPreview[]);

    const associatedAccountsIds = computed(() => {
      return associatedAccounts.value.map((a) => a.id);
    });

    // Cannot use directly mailboxItem because it is a computedRef (not compatible with Office js lib)
    let currentMailBoxItem = Office.context.mailbox.item;
    let from = ref(currentMailBoxItem?.from as Office.EmailAddressDetails);

    /**
     * Watch Outlook mailbox item changed
     */
    watch(mailboxItem, async () => {
      currentMailBoxItem = Office.context.mailbox.item;
      from.value = currentMailBoxItem?.from as Office.EmailAddressDetails;
      debounceSearch();
      await setAssociatedAccounts();
    });

    /**
     * When component is mounted
     */
    onMounted(async () => {
      await initLicenceLevelValidity();
      await setAssociatedAccounts();
    });

    /**
     * Initialize licence level validity
     */
    async function initLicenceLevelValidity() {
      let result = await licenseApi.getMobiCrmLicense();
      if (result.isSuccess) {
        isLicenseLevelValid.value = result.data.level >= licenseLevel.PRO;
      }
    }

    /**
     * Navigates to account url
     * @param accountUrl
     */
    function navigateToAccountUrl(accountUrl: string) {
      if (!accountUrl) {
        return;
      }
      if (Office.context.ui.openBrowserWindow) {
        Office.context.ui.openBrowserWindow(accountUrl);
      } else {
        window.open(accountUrl);
      }
    }

    /**
     * Runs the search on the server
     **/
    const debounceSearch = debounce(async function () {
      isLoading.value = true;
      if (searchText.value) {
        const result = await accountsApi.search(searchText.value);
        if (result.isSuccess) {
          accountsList.value = result.data.items.filter(
            (b: AccountPreview) => !associatedAccountsIds.value.includes(b.id)
          );
        }
      } else {
        accountsList.value = [];
      }
      isLoading.value = false;
    }, 500);

    /**
     * Associates current email to selected account
     * @param accountId
     */
    async function associateCurrentEmailToAccount(accountId: string) {
      isLoading.value = true;
      await associateEmailToAccount(accountId, Office.context.mailbox.item, associateCurrentEmailToAccountCallback);
    }

    /**
     * Associates current email to selected contact callback
     */
    async function associateCurrentEmailToAccountCallback() {
      await setAssociatedAccounts();
      isLoading.value = false;
    }

    /**
     * Sets associated accounts
     */
    async function setAssociatedAccounts() {
      isLoading.value = true;
      if (Office.context.mailbox.item) {
        let result = await emailMessagesApi.getAssociatedAccounts(Office.context.mailbox.item.itemId);
        if (result.isSuccess) {
          associatedAccounts.value = result.data;
        } else {
          associatedAccounts.value = [];
        }
      }
      isLoading.value = false;
    }

    return {
      from,
      searchText,
      navigateToAccountUrl,
      isLoading,
      isLicenseLevelValid,
      debounceSearch,
      accountsList,
      associatedAccountsIds,
      associatedAccounts,
      associateCurrentEmailToAccount,
    };
  },
});
