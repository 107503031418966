
import { defineComponent, onMounted, ref, toRef, watch } from "vue";
import { useRouter } from "../router";
import NewAccount from "../model/NewAccount";
import StringHelper from "../helpers/stringHelper";
import { requiredRule, emailRule, phoneRule } from "../helpers/validationRules";
import { useOfficeState } from "../store/officeState";
import CrmAccountTypeSelect from "../components/Account/AccountTypeSelect.vue";
import CrmAccountPotentialSelect from "../components/Account/AccountPotentialSelect.vue";
import CrmUserSelect from "../components/Common/UserSelect.vue";
import entityCreation from "./entityCreation";
import sireneApi from "../api/sirene";
import tenantSettingApi from "../api/tenantSetting/tenantSettings";
import SireneCompany from "../model/SireneCompany";
import oidcManager from "../authentication/oidcManager";
import { AccountType } from "../model/AccountType";
import { PermissionAction } from "../model/Permissions";
import PermissionHelper from "../helpers/PermissionHelper";

const ACCOUNT_CREATION_TYPE_FILTER = "882959db-b222-48cf-8782-14783d945524";

export default defineComponent({
  name: "CrmAccountCreation",
  components: { CrmAccountTypeSelect, CrmAccountPotentialSelect, CrmUserSelect },
  props: {
    emailAddress: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    isSender: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { getPhoneNumbersFromBody, searchOtherPhones, searchMobilePhones } = entityCreation();
    let form = ref({} as NewAccount);
    let rules = {
      companyName: [requiredRule()],
      email: [emailRule()],
      phone: [phoneRule()],
      mobile: [phoneRule()],
    };
    let page = 1;
    let totalPages = 1;
    const maxRetrievedPages = 5;

    const { mailboxItem } = useOfficeState();
    const emailAddress = toRef(props, "emailAddress");
    const isSender = toRef(props, "isSender");
    const formComponent = ref(null);
    const accountCreationTypeFilter = ref(null);

    /**
     * Watch Outlook mailbox item changed
     */
    watch(mailboxItem, () => {
      useRouter().push({
        name: "email",
      });
    });

    /**
     * When component is mounted
     */
    onMounted(async () => {
      await initDefaultValues();
      await getPhoneNumbersFromBody(form.value, isSender.value);
    });

    /**
     * Initializes default values
     */
    async function initDefaultValues() {
      let result = await tenantSettingApi.getValue(ACCOUNT_CREATION_TYPE_FILTER);
      if (result.isSuccess) {
        accountCreationTypeFilter.value = result.data;
      }

      // Company name
      form.value.companyName = StringHelper.getCompanyNameFromEmail(emailAddress.value);

      // email
      form.value.email = emailAddress.value;

      // Manager
      form.value.accountManagerId = oidcManager.userProfile?.sub;

      form.value.type = PermissionHelper.hasAccountPermission(AccountType.PROSPECT, PermissionAction.CREATE)
        ? AccountType.PROSPECT
        : AccountType.OTHER;
    }

    /**
     * Tests if the string is a siret or siren number
     * @param {string} searchString - Search string
     * @returns {boolean}
     */
    function isSirenSiret(searchString: string) {
      searchString = searchString.replace(/\s/g, "");
      const number = parseInt(searchString, 10);

      if (!number) {
        return false;
      }

      return searchString.length >= 14 || searchString.length === 9;
    }

    /**
     * Search a company by siren or siret number.
     * @param {string} searchString - the search string.
     * @returns {object} company information.
     */
    async function searchSirenSiret(searchString: string) {
      searchString = searchString.replace(/\s/g, "");
      const number = parseInt(searchString, 10);

      if (!number) {
        return null;
      }

      if (searchString.length >= 14) {
        const result = await sireneApi.searchFromSiret(searchString);
        if (result.status === 200) {
          return result.data.etablissement;
        }
      } else if (searchString.length === 9) {
        const result = await sireneApi.searchFromSiren(searchString);
        if (result.status === 200) {
          return result.data.siege_social;
        }
      }

      return null;
    }

    /**
     * Searches the companies from the api
     * @param {string} searchString - the search string
     * @returns {Array<object>} company information.
     */
    async function searchCompaniesFromSiretApi(searchString: string) {
      let result: any = [];

      if (isSirenSiret(searchString)) {
        const sirenSiretResult = await searchSirenSiret(searchString);
        if (sirenSiretResult) {
          result.push(sirenSiretResult);
        }
      } else {
        const searchResult = await sireneApi.search(searchString, page);
        if (searchResult.status === 200) {
          page = parseInt(searchResult.data.page);
          totalPages = searchResult.data.total_pages;
          result = searchResult.data.etablissement;
        }
      }

      return result;
    }

    /**
     * Searches the companies from the api
     * @param {string} searchString - the search string
     * @param {any} callback - Callback function for returning results
     */
    async function searchCompanies(searchString: string, callback: any) {
      if (!searchString) {
        return;
      }

      let companies: any = [];
      page = 1;

      do {
        const result = await searchCompaniesFromSiretApi(searchString);
        companies.push(...result);
        page += 1;
      } while (page < totalPages && page <= maxRetrievedPages);

      callback(companies);
    }

    /**
     * Sets company information
     * @param company
     */
    function setCompanyInformation(company: SireneCompany) {
      form.value.companyName = company.l1_normalisee;
      form.value.siret = company.siret;
      form.value.apeCode = company.activite_principale;
      form.value.unstructuredAddress = `${company.l4_normalisee ?? ""} ${company.l5_normalisee ?? ""}\n${
        company.l6_normalisee ?? ""
      } ${company.l7_normalisee ?? ""}`.trim();
    }

    return {
      form,
      rules,
      initDefaultValues,
      formComponent,
      getPhoneNumbersFromBody,
      searchOtherPhones,
      searchMobilePhones,
      setCompanyInformation,
      searchCompanies,
      accountCreationTypeFilter,
    };
  },
});
