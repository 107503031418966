<template>
  <div :class="typeClass">
    <div>P{{ potential }}</div>
    <div>R{{ rating }}</div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CrmAccountBadge",

  props: {
    type: {
      type: Number,
      default: 0,
    },
    potential: {
      type: Number,
      default: 0,
    },
    rating: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    /**
     * Gets the CSS class for the account type.
     * @returns {string} A CSS class name.
     */
    typeClass: function () {
      switch (this.type) {
        case 0:
          return "account-badge other";
        case 1:
          return "account-badge customer";
        case 2:
          return "account-badge prospect";
        default:
          return null;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.account-badge {
  color: $crm-color-white;
  width: 3.8rem;
  min-width: 3.8rem;
  height: 3.8rem;
  font-size: $crm-font-size5;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 0.4rem;
  font-weight: $crm-font-weight-bold;
  padding-top: 0.5rem;
  box-sizing: border-box;
  box-shadow: 0.1rem 0.1rem 0.1rem 0 $crm-color-grey5;
}

.customer {
  background-color: $crm-color-grey7;
}

.prospect {
  background-color: $crm-color-grey5;
}

.other {
  background-color: $crm-color-grey3;
}
</style>
