
import { defineComponent } from "vue";

export default defineComponent({
  name: "CrmEmailAddressDetailsPreview",
  props: {
    emailAddressDetail: {
      type: Object,
      default: () => null,
    },
    alreadyExists: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["createContact"],
  computed: {
    /**
     * Gets the initials from first name and last name
     * @returns {string} the initials
     */
    initials(): string {
      return this.getInitials(this.emailAddressDetail?.displayName);
    },
  },
  methods: {
    /**
     * Gets initials from name
     * @param name
     */
    getInitials(name: string): string {
      let initials = "";
      let initialsArray = name?.split(" ") as Array<string>;

      if (initialsArray && initialsArray.length > 1) {
        const firstInitial = initialsArray[0].charAt(0);
        const lastInitial = initialsArray[initialsArray.length - 1].charAt(0);
        initials = firstInitial + lastInitial;
      } else {
        initials = name?.substring(0, 2);
      }

      return initials.toUpperCase();
    },
  },
});
