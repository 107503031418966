import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    modelValue: _ctx.selectedContactId,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedContactId) = $event)),
    filterable: "",
    remote: "",
    clearable: _ctx.clearable,
    disabled: _ctx.disabled,
    placeholder: _ctx.$t('ContactSelect.Placeholder'),
    "remote-method": _ctx.searchContacts,
    loading: _ctx.isLoading,
    style: {"width":"100%"},
    onClear: _ctx.onClear,
    onChange: _ctx.selectionChanged
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contacts, (item) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: item.id,
          label: `${item.firstName} ${item.lastName}`,
          value: item.id
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(`${item.firstName} ${item.lastName}`), 1)
          ]),
          _: 2
        }, 1032, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "clearable", "disabled", "placeholder", "remote-method", "loading", "onClear", "onChange"]))
}