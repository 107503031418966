
import { computed, defineComponent, onMounted, ref, toRef, watch } from "vue";
import { useRouter } from "../router";
import { requiredRule } from "../helpers/validationRules";
import { useOfficeState } from "../store/officeState";
import CrmUserSelect from "../components/Common/UserSelect.vue";
import entityCreation from "./entityCreation";
import { ElForm } from "element-plus";
import NewLead from "../model/NewLead";
import oidcManager from "../authentication/oidcManager";
import StringHelper from "../helpers/stringHelper";
import CrmContactSelect from "../components/Contact/ContactSelect.vue";
import PermissionHelper from "../helpers/PermissionHelper";
import { PermissionAction, PermissionItem } from "../model/Permissions";
import CrmAccountSelect from "@/components/Account/AccountSelect.vue";

export default defineComponent({
  name: "CrmLeadCreation",
  components: { CrmAccountSelect, CrmContactSelect, CrmUserSelect },
  props: {
    emailAddress: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    accountVisible: {
      type: Boolean,
      default: true,
    },
  },

  emits: ["leadContactChanged", "createNewContact", "leadAccountChanged", "createNewAccount"],

  setup(props, context) {
    const { getPhoneNumbersFromBody, searchOtherPhones, searchMobilePhones } = entityCreation();
    let form = ref({} as NewLead);
    let rules = {
      title: [requiredRule()],
    };

    const { mailboxItem } = useOfficeState();
    const emailAddress = toRef(props, "emailAddress");
    const formComponent = ref(null);

    const hasAccountCreationPermission = computed(() =>
      PermissionHelper.hasPermission({ item: PermissionItem.ACCOUNT, action: PermissionAction.CREATE, userId: null })
    );

    const hasContactCreationPermission = computed(() =>
      PermissionHelper.hasPermission({ item: PermissionItem.CONTACT, action: PermissionAction.CREATE, userId: null })
    );

    /**
     * Watch Outlook mailbox item changed
     */
    watch(mailboxItem, () => {
      useRouter().push({
        name: "email",
      });
    });

    /**
     * When component is mounted
     */
    onMounted(async () => {
      await initDefaultValues();
    });

    /**
     * Initializes default values
     */
    async function initDefaultValues() {
      form.value.leadManagerId = oidcManager.userProfile?.sub;
      form.value.title = StringHelper.getCompanyNameFromEmail(emailAddress.value);
    }

    /**
     * Creates new contact
     */
    function createNewContact() {
      form.value.contactId = "";
      context.emit("createNewContact");
    }

    /**
     * Contact changed event
     * @param newContactId
     */
    async function contactChanged(newContactId: string) {
      context.emit("leadContactChanged", newContactId);
    }

    /**
     * Checks if form is valid
     * @returns {boolean} True if the form is valid otherwise false
     */
    async function isFormValid() {
      let isValid = false;
      await (formComponent.value as typeof ElForm).validate((valid: boolean) => {
        isValid = valid;
      });
      return isValid;
    }

    /**
     * Account changed event
     * @param newAccountId
     */
    async function accountChanged(newAccountId: string) {
      context.emit("leadAccountChanged", newAccountId);
    }

    /**
     * Creates new account
     */
    function createNewAccount() {
      form.value.accountId = "";
      context.emit("createNewAccount");
    }

    return {
      form,
      rules,
      formComponent,
      getPhoneNumbersFromBody,
      searchOtherPhones,
      searchMobilePhones,
      isFormValid,
      initDefaultValues,
      hasContactCreationPermission,
      createNewContact,
      contactChanged,
      hasAccountCreationPermission,
      accountChanged,
      createNewAccount,
    };
  },
});
