
import { computed, defineComponent } from "vue";
import oidcManager from "../authentication/oidcManager";
import VersionHelper from "../helpers/VersionHelper";
import { useRouter } from "../router";

export default defineComponent({
  name: "CrmContactEmail",
  props: {
    visible: Boolean,
  },
  emits: ["update:visible"],
  setup(props, { emit }) {
    const year = new Date().getFullYear();
    const appVersion = computed(() => {
      return VersionHelper.getAppVersion();
    });
    const router = useRouter();

    const isMenuVisible = computed({
      get(): boolean {
        return props.visible;
      },
      set(value: boolean) {
        emit("update:visible", value);
      },
    });

    /**
     * Gets the main path
     * @returns {string} main path
     */
    const mainPath = computed(() => {
      return router.currentRoute.value.name;
    });

    /**
     * Logout user
     */
    function logout() {
      oidcManager.signOut();
    }

    /**
     * Closes sidebar
     */
    function close() {
      isMenuVisible.value = false;
    }

    return {
      year,
      appVersion,
      isMenuVisible,
      logout,
      close,
      mainPath,
    };
  },
});
