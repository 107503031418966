
import contactsApi from "../../api/contact/contactsApi";
import contactDetailsApi from "../../api/contact/contactDetailsApi";
import { defineComponent } from "vue";
import ContactPreview from "../../model/ContactPreview";

export default defineComponent({
  name: "CrmContactSelect",

  props: {
    modelValue: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    contactType: {
      type: Number,
      default: null,
    },
  },

  emits: ["update:modelValue", "searchTextChanged", "change"],

  /**
   * Gets the component data
   * @returns {object} Component data
   */
  data() {
    return {
      contactId: null,
      contacts: [] as ContactPreview[],
      isLoading: false,
      isFetchingContact: false,
    };
  },

  computed: {
    selectedContactId: {
      /**
       * Gets the contact id
       * @returns {string}
       */
      get(): string {
        if (this.isFetchingContact) {
          return this.$t("ContactSelect.LoadingText");
        }
        return this.modelValue ?? "";
      },

      /**
       * On option selected
       * @param {string} value - selected contact id
       */
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
  },

  watch: {
    /**
     * When the prop value changes
     * @param {string} newValue - new value
     * @returns {Promise}
     */
    modelValue: async function (newValue) {
      await this.getContact(newValue);
    },
  },

  /**
   * When component is mounted
   * @returns {Promise}
   */
  async mounted() {
    await this.getContact(this.modelValue ?? "");
  },

  methods: {
    /**
     * Fills the html options with the corresponding contacts on the backend
     * @param {string} searchText - search text
     * @returns {Promise}
     */
    async searchContacts(searchText: string) {
      await this.searchTextChanged(searchText);
      if (searchText !== "") {
        await this.getContacts(searchText);
      } else {
        this.contacts = [];
      }
    },

    /**
     * When fetching text changed
     * @param {string} newValue - Search text
     */
    async searchTextChanged(newValue: string) {
      this.$emit("searchTextChanged", newValue);
    },

    /**
     * Fetches the contacts from the backend
     * @param {string} searchText - search text
     * @returns {Promise}
     */
    async getContacts(searchText: string) {
      this.isLoading = true;
      const filter = {
        pageSize: 100,
        searchText: searchText,
      };
      let result = await contactsApi.searchContacts(filter);
      this.isLoading = false;

      if (result.isSuccess) {
        this.contacts = result.data.items;
      }
    },

    /**
     * Fetches the specific contact from the backend
     * @param {string} contactId - Contact id
     * @returns {Promise}
     */
    async getContact(contactId: string) {
      if (!contactId) {
        return;
      }

      this.isFetchingContact = true;
      const result = await contactDetailsApi.getContactSummary(contactId);

      this.isFetchingContact = false;

      if (result.isSuccess) {
        this.contacts = [result.data] as ContactPreview[];
      }
    },

    /**
     * When clearing contact selection
     */
    onClear() {
      this.contacts = [];
    },

    /**
     * Handles selection changed
     * @param {string} value - the selected value
     */
    selectionChanged(value: string) {
      this.$emit("change", value);
    },
  },
});
