
import { defineComponent, ref, watch } from "vue";
import CrmNavbar from "./Navbar.vue";
import CrmContactCreation from "./ContactCreation.vue";
import CrmLeadCreation from "./LeadCreation.vue";
import { useRouter } from "../router";
import { useOfficeState } from "../store/officeState";
import NewLead from "../model/NewLead";
import leadApi from "../api/lead/leadsApi";
import NewContact from "../model/NewContact";
import contactsApi from "../api/contact/contactsApi";
import PhoneHelper from "../helpers/phoneHelper";
import { useEmailService } from "../services/emailService";
import CrmAccountCreation from "@/views/AccountCreation.vue";
import NewAccount from "../model/NewAccount";
import accountsApi from "../api/account/accountsApi";

/* global Office */

export default defineComponent({
  name: "CrmEntityCreation",
  components: { CrmAccountCreation, CrmLeadCreation, CrmNavbar, CrmContactCreation },

  props: {
    emailAddress: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    isSender: {
      type: [Boolean, String],
      default: false,
    },
  },

  setup() {
    const { mailboxItem } = useOfficeState();
    const activeTabName = ref("lead");
    let isCreatingLead = ref(false);
    let contactTabVisible = ref(false);
    let accountTabVisible = ref(false);
    const contactCreationComponent = ref<InstanceType<typeof CrmContactCreation>>();
    const accountCreationComponent = ref<InstanceType<typeof CrmAccountCreation>>();
    const leadCreationComponent = ref<InstanceType<typeof CrmLeadCreation>>();
    const { associateEmailToLead } = useEmailService();

    /**
     * Watch Outlook mailbox item changed
     */
    watch(mailboxItem, () => {
      useRouter().push({
        name: "email",
      });
    });

    /**
     * Shows the contact tab visibility
     */
    function showContactTab() {
      contactTabVisible.value = true;
      activeTabName.value = "contact";
    }

    /**
     * Creates the new Lead
     */
    async function createNewLead() {
      if (leadCreationComponent.value?.isFormValid()) {
        isCreatingLead.value = true;
        let leadForm = leadCreationComponent.value?.form as NewLead;

        // Contact creation
        let contactForm = contactCreationComponent.value?.form as NewContact;
        if (contactForm) {
          contactForm.phone = PhoneHelper.parse(contactForm.phone);
          contactForm.mobile = PhoneHelper.parse(contactForm.mobile);
          if (!contactForm.accountId && contactForm.lastName && contactTabVisible.value) {
            const contactCreationResult = await contactsApi.createContact(contactForm);
            if (contactCreationResult.isSuccess) {
              leadForm.contactId = contactCreationResult.data;
            }
          }
        }

        // Account creation
        let accountForm = accountCreationComponent.value?.form as NewAccount;
        if (accountForm) {
          accountForm.phone = PhoneHelper.parse(accountForm.phone);
          accountForm.mobile = PhoneHelper.parse(accountForm.mobile);
          if (!leadForm.accountId && accountForm.companyName && accountTabVisible.value) {
            const accountCreationResult = await accountsApi.createAccount(accountForm);
            if (accountCreationResult.isSuccess) {
              leadForm.accountId = accountCreationResult.data;
            }
          }
        }

        // Lead creation
        const leadCreationResult = await leadApi.createLead(leadForm);
        if (leadCreationResult.isSuccess) {
          // associate email
          await associateEmailToLead(
            leadCreationResult.data,
            Office.context.mailbox.item,
            associateCurrentEmailToLeadCallback
          );
        } else {
          isCreatingLead.value = false;
        }
      } else {
        return false;
      }
    }

    /**
     * Associates current email to selected contact callback
     */
    async function associateCurrentEmailToLeadCallback() {
      useRouter().go(-1);
      isCreatingLead.value = false;
    }

    /**
     * Updates the contact tab visibility
     * @param leadContactId
     */
    function updateContactTabVisibility(leadContactId: string) {
      contactTabVisible.value &&= !leadContactId;
    }

    /**
     * Updates the account tab visibility
     * @param contactAccountId
     */
    function updateAccountTabVisibility(contactAccountId: string) {
      accountTabVisible.value &&= !contactAccountId;
    }

    /**
     * Shows the account tab visibility
     */
    function showAccountTab() {
      accountTabVisible.value = true;
      activeTabName.value = "account";
    }

    return {
      activeTabName,
      createNewLead,
      isCreatingLead,
      contactCreationComponent,
      leadCreationComponent,
      showContactTab,
      contactTabVisible,
      updateContactTabVisibility,
      updateAccountTabVisibility,
      showAccountTab,
      accountTabVisible,
      accountCreationComponent,
    };
  },
});
