
/* global Office */

import Dialog = Office.Dialog;
import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import oidcManager from "../authentication/oidcManager";
import buildOidcSettings from "../config/oidcSettings";
import appSettings from "../config/appSettings";
import CrmSpinner from "../components/Common/Spinner.vue";
import VersionHelper from "../helpers/VersionHelper";
import { User } from "oidc-client";
import { ERROR_PREFIX } from "../model/Constants";

let dialog: Dialog;

export default defineComponent({
  name: "CrmWelcome",
  components: { CrmSpinner },
  setup() {
    const router = useRouter();
    const isAuthenticating = ref(false);
    const year = new Date().getFullYear();
    const errorMessage = ref(null as string | null);

    /**
     * Gets the formatted application version
     * @returns {string} the application version
     */
    const appVersion = computed(() => {
      return VersionHelper.getAppVersion();
    });

    /**
     * Process popup message
     */
    async function processMessage(arg: { message: string; origin: string | undefined } | { error: number }) {
      await oidcManager.reset(buildOidcSettings(appSettings));
      isAuthenticating.value = false;
      dialog.close();

      const message = (arg as { message: string })?.message;
      if (message) {
        if (message?.startsWith(ERROR_PREFIX)) {
          errorMessage.value = message;
          return;
        } else {
          const user: User = JSON.parse(message);
          user.toStorageString = function (): string {
            return JSON.stringify(user);
          };
          await oidcManager.storeUser(user);
          await oidcManager.reset(buildOidcSettings(appSettings));
        }
      }

      await router.push("/");
    }

    /**
     * Process dialog closed
     */
    function processDialogClosed() {
      isAuthenticating.value = false;
    }

    /**
     * Opens office popup
     */
    async function openLogin() {
      isAuthenticating.value = true;
      Office.context.ui.displayDialogAsync(
        `${oidcManager.appRootUrl}oidc-login.html`,
        {
          height: 60,
          width: 50,
          promptBeforeOpen: false,
        },
        function (asyncResult) {
          dialog = asyncResult.value;
          dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
          dialog.addEventHandler(Office.EventType.DialogEventReceived, processDialogClosed);
        }
      );
    }

    return {
      isAuthenticating,
      year,
      appVersion,
      errorMessage,
      openLogin,
    };
  },
});
