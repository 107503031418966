
import { defineComponent } from "vue";
import businessApi from "../../api/business/businessApi";
import CrmProgressCircle from "../../components/Business/ProgressCircle.vue";

export default defineComponent({
  name: "CrmBusinessPreview",
  components: {
    CrmProgressCircle,
  },
  props: {
    business: {
      type: Object,
      default: () => null,
    },
    isLinked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["linkToBusiness", "navigateToMobiCRM"],
  /**
   * Gets the component data
   * @returns {object} Component data
   */
  data() {
    return {
      businessReferenceData: null,
    };
  },
  /**
   * When component is created
   */
  async created() {
    await this.loadBusinessReferenceData();
  },
  methods: {
    /**
     * Loads the business reference data
     * @return {Promise<void>}
     */
    async loadBusinessReferenceData() {
      const refDataResult = await businessApi.getReferenceData();
      if (refDataResult.isSuccess) {
        this.businessReferenceData = refDataResult.data;
      }
    },
  },
});
