import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64e7a59e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "city" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    modelValue: _ctx.selectedAccountId,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedAccountId) = $event)),
    filterable: "",
    remote: "",
    clearable: _ctx.clearable,
    disabled: _ctx.disabled,
    placeholder: _ctx.$t('AccountSelect.Placeholder'),
    "remote-method": _ctx.searchAccounts,
    loading: _ctx.isLoading,
    style: {"width":"100%"},
    onClear: _ctx.onClear,
    onChange: _ctx.selectionChanged
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accounts, (item) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: item.id,
          label: item.companyName,
          value: item.id,
          disabled: item.isDeleted
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(item.companyName), 1),
            _createElementVNode("span", _hoisted_1, _toDisplayString(item.city), 1)
          ]),
          _: 2
        }, 1032, ["label", "value", "disabled"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "clearable", "disabled", "placeholder", "remote-method", "loading", "onClear", "onChange"]))
}