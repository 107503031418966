
import contactsApi from "../api/contact/contactsApi";
import licenseApi from "../api/license/licenseApi";
import emailMessagesApi from "../api/emailMessage/emailMessagesApi";
import CrmContactPreview from "../components/Contact/ContactPreview.vue";
import CrmEmailAddressDetailsPreview from "../components/Email/EmailAddressDetailsPreview.vue";
import { defineComponent, onMounted, ref, watch } from "vue";
import ContactPreview from "../model/ContactPreview";
import CrmNavbar from "./Navbar.vue";
import { useOfficeState } from "../store/officeState";
import { useEmailService } from "../services/emailService";
import { useRouter } from "../router";
import { licenseLevel } from "../model/LicenseLevel";
import appSettings from "../config/appSettings";
import usersApi from "../api/user/users";
import PermissionHelper from "../helpers/PermissionHelper";

/* global Office */

export default defineComponent({
  name: "CrmContactEmail",
  components: { CrmNavbar, CrmContactPreview, CrmEmailAddressDetailsPreview },
  setup() {
    const contacts = ref([] as ContactPreview[]);
    const associatedContactsIds = ref([] as string[]);
    const senderEmail = ref("" as string);
    const isLoading = ref(false as boolean);
    const emailAddresses = ref([] as Office.EmailAddressDetails[]);
    const { mailboxItem } = useOfficeState();
    const { associateEmailToContact } = useEmailService();
    const router = useRouter();
    const isLicenseLevelValid = ref(true);

    // Cannot use directly mailboxItem because it is a computedRef (not compatible with Office js lib)
    let currentMailBoxItem = Office.context.mailbox.item;
    let from = ref(currentMailBoxItem?.from as Office.EmailAddressDetails);

    /**
     * Watch Outlook mailbox item changed
     */
    watch(mailboxItem, () => {
      currentMailBoxItem = Office.context.mailbox.item;
      from.value = currentMailBoxItem?.from as Office.EmailAddressDetails;
      localStorage.setItem(appSettings.LAST_ACCOUNT_ID_STORAGE_KEY, "");
      searchContactsByEmail();
      setAssociatedContactsIds();
    });

    /**
     * When component is mounted
     */
    onMounted(async () => {
      await initLicenceLevelValidity();
      await searchContactsByEmail();
      await setAssociatedContactsIds();
      await PermissionHelper.initialize();
    });

    /**
     * Initialize licence level validity
     */
    async function initLicenceLevelValidity() {
      let result = await licenseApi.getMobiCrmLicense();
      if (result.isSuccess) {
        isLicenseLevelValid.value = result.data.level >= licenseLevel.PRO;
      }
    }

    /**
     * Search contacts by email
     */
    async function searchContactsByEmail() {
      contacts.value = [];
      if (!currentMailBoxItem?.from) {
        return;
      }

      isLoading.value = true;

      senderEmail.value = currentMailBoxItem.from.emailAddress;
      const itemEmailAddresses = [currentMailBoxItem.from, ...currentMailBoxItem.to, ...currentMailBoxItem.cc];

      let contactsByEmailResult = await contactsApi.getContactsByEmails(itemEmailAddresses.map((t) => t.emailAddress));
      if (contactsByEmailResult.isSuccess) {
        contacts.value = contactsByEmailResult.data as ContactPreview[];
        contacts.value.sort((a, b) => a.lastName?.localeCompare(b.lastName));
      }
      const foundContactsEmailAddresses = contacts.value.map((c) => c.emailAddress.trim());
      emailAddresses.value = itemEmailAddresses.filter(function (i) {
        return !foundContactsEmailAddresses.includes(i.emailAddress);
      });

      // Exclude MobiCRM users from contact creation
      let result = await usersApi.getUsers();
      if (result.isSuccess) {
        const usersEmailAddresses = result.data.map((c: any) => c.email);
        emailAddresses.value = emailAddresses.value.filter(function (i) {
          return !usersEmailAddresses.includes(i.emailAddress);
        });
      }
      emailAddresses.value.sort((a, b) => a.displayName.localeCompare(b.displayName));

      isLoading.value = false;
    }

    /**
     * Sets associated contacts ids
     */
    async function setAssociatedContactsIds() {
      if (Office.context.mailbox.item) {
        let result = await emailMessagesApi.getAssociatedContactsId(Office.context.mailbox.item.itemId);
        if (result.isSuccess) {
          associatedContactsIds.value = result.data;
        }
      }
    }

    /**
     * Associates current email to selected contact
     * @param contactId
     */
    async function associateCurrentEmailToContact(contactId: string) {
      isLoading.value = true;
      await associateEmailToContact(contactId, Office.context.mailbox.item, associateCurrentEmailToContactCallback);
    }

    /**
     * Navigates to contact url
     * @param contactUrl
     */
    function navigateToContactUrl(contactUrl: string) {
      if (!contactUrl) {
        return;
      }
      if (Office.context.ui.openBrowserWindow) {
        Office.context.ui.openBrowserWindow(contactUrl);
      } else {
        window.open(contactUrl);
      }
    }

    /**
     * Navigates to entity creation
     */
    async function navigateToEntityCreation(emailAddressDetails: Office.EmailAddressDetails) {
      await router.push({
        name: "entity-creation",
        params: {
          emailAddress: emailAddressDetails.emailAddress,
          name: emailAddressDetails.displayName,
          isSender: emailAddressDetails.emailAddress === senderEmail.value,
        },
      });
    }

    /**
     * Associates current email to selected contact callback
     */
    async function associateCurrentEmailToContactCallback() {
      await setAssociatedContactsIds();
      isLoading.value = false;
    }

    return {
      contacts,
      from,
      associatedContactsIds,
      associateCurrentEmailToContact,
      navigateToContactUrl,
      navigateToEntityCreation,
      emailAddresses,
      isLoading,
      senderEmail,
      isLicenseLevelValid,
    };
  },
});
