
import licenseApi from "../api/license/licenseApi";
import { defineComponent, onMounted, ref, watch, computed } from "vue";
import CrmNavbar from "./Navbar.vue";
import { useOfficeState } from "../store/officeState";
import { licenseLevel } from "../model/LicenseLevel";
import { debounce } from "lodash";
import businessApi from "../api/business/businessApi";
import BusinessPreview from "../model/BusinessPreview";
import CrmBusinessPreview from "../components/Business/BusinessPreview.vue";
import { useEmailService } from "../services/emailService";
import emailMessagesApi from "../api/emailMessage/emailMessagesApi";

/* global Office */

export default defineComponent({
  name: "CrmBusinessEmail",
  components: { CrmNavbar, CrmBusinessPreview },
  setup() {
    const searchText = ref("" as string);
    const isLoading = ref(false as boolean);
    const { mailboxItem } = useOfficeState();
    const isLicenseLevelValid = ref(true);
    const businessList = ref([] as BusinessPreview[]);
    const { associateEmailToBusiness } = useEmailService();
    const associatedBusinesses = ref([] as BusinessPreview[]);

    const associatedBusinessesIds = computed(() => {
      return associatedBusinesses.value.map((ab) => ab.id);
    });

    // Cannot use directly mailboxItem because it is a computedRef (not compatible with Office js lib)
    let currentMailBoxItem = Office.context.mailbox.item;
    let from = ref(currentMailBoxItem?.from as Office.EmailAddressDetails);

    /**
     * Watch Outlook mailbox item changed
     */
    watch(mailboxItem, async () => {
      currentMailBoxItem = Office.context.mailbox.item;
      from.value = currentMailBoxItem?.from as Office.EmailAddressDetails;
      debounceSearch();
      await setAssociatedBusinesses();
    });

    /**
     * When component is mounted
     */
    onMounted(async () => {
      await initLicenceLevelValidity();
      await setAssociatedBusinesses();
    });

    /**
     * Initialize licence level validity
     */
    async function initLicenceLevelValidity() {
      let result = await licenseApi.getMobiCrmLicense();
      if (result.isSuccess) {
        isLicenseLevelValid.value = result.data.level >= licenseLevel.PRO;
      }
    }

    /**
     * Navigates to business url
     * @param businessUrl
     */
    function navigateToBusinessUrl(businessUrl: string) {
      if (!businessUrl) {
        return;
      }
      if (Office.context.ui.openBrowserWindow) {
        Office.context.ui.openBrowserWindow(businessUrl);
      } else {
        window.open(businessUrl);
      }
    }

    /**
     * Runs the search on the server
     **/
    const debounceSearch = debounce(async function () {
      isLoading.value = true;
      if (searchText.value) {
        const result = await businessApi.quicksearch(searchText.value);
        if (result.isSuccess) {
          businessList.value = result.data.items.filter(
            (b: BusinessPreview) => !associatedBusinessesIds.value.includes(b.id)
          );
        }
      } else {
        businessList.value = [];
      }
      isLoading.value = false;
    }, 500);

    /**
     * Associates current email to selected business
     * @param businessId
     */
    async function associateCurrentEmailToBusiness(businessId: string) {
      isLoading.value = true;
      await associateEmailToBusiness(businessId, Office.context.mailbox.item, associateCurrentEmailToBusinessCallback);
    }

    /**
     * Associates current email to selected contact callback
     */
    async function associateCurrentEmailToBusinessCallback() {
      await setAssociatedBusinesses();
      isLoading.value = false;
    }

    /**
     * Sets associated businesses ids
     */
    async function setAssociatedBusinesses() {
      isLoading.value = true;
      if (Office.context.mailbox.item) {
        let result = await emailMessagesApi.getAssociatedBusinesses(Office.context.mailbox.item.itemId);
        if (result.isSuccess) {
          associatedBusinesses.value = result.data;
        } else {
          associatedBusinesses.value = [];
        }
      }
      isLoading.value = false;
    }

    return {
      from,
      searchText,
      navigateToBusinessUrl,
      isLoading,
      isLicenseLevelValid,
      debounceSearch,
      businessList,
      associatedBusinesses,
      associateCurrentEmailToBusiness,
      associatedBusinessesIds,
    };
  },
});
