
import licenseApi from "../api/license/licenseApi";
import { defineComponent, onMounted, ref, watch, computed } from "vue";
import CrmNavbar from "./Navbar.vue";
import { useOfficeState } from "../store/officeState";
import { licenseLevel } from "../model/LicenseLevel";
import { debounce } from "lodash";
import leadApi from "../api/lead/leadsApi";
import LeadPreview from "../model/LeadPreview";
import CrmLeadPreview from "../components/Lead/LeadPreview.vue";
import { useEmailService } from "../services/emailService";
import emailMessagesApi from "../api/emailMessage/emailMessagesApi";
import usersApi from "../api/user/users";
import { useRouter } from "../router";

/* global Office */

export default defineComponent({
  name: "CrmLeadEmail",
  components: { CrmNavbar, CrmLeadPreview },
  setup() {
    const searchText = ref("" as string);
    const isLoading = ref(false as boolean);
    const { mailboxItem } = useOfficeState();
    const isLicenseLevelValid = ref(true);
    const leadList = ref([] as LeadPreview[]);
    const { associateEmailToLead } = useEmailService();
    const associatedLeads = ref([] as LeadPreview[]);
    const senderEmail = ref("" as string);
    const emailAddresses = ref([] as Office.EmailAddressDetails[]);
    const router = useRouter();

    const associatedLeadsIds = computed(() => {
      return associatedLeads.value.map((ab) => ab.id);
    });

    // Cannot use directly mailboxItem because it is a computedRef (not compatible with Office js lib)
    let currentMailBoxItem = Office.context.mailbox.item;
    let from = ref(currentMailBoxItem?.from as Office.EmailAddressDetails);

    /**
     * Watch Outlook mailbox item changed
     */
    watch(mailboxItem, async () => {
      currentMailBoxItem = Office.context.mailbox.item;
      from.value = currentMailBoxItem?.from as Office.EmailAddressDetails;
      debounceSearch();
      await setAssociatedLeads();
      await searchLeadsByEmail();
    });

    /**
     * When component is mounted
     */
    onMounted(async () => {
      await initLicenceLevelValidity();
      await setAssociatedLeads();
      await searchLeadsByEmail();
    });

    /**
     * Initialize licence level validity
     */
    async function initLicenceLevelValidity() {
      let result = await licenseApi.getMobiCrmLicense();
      if (result.isSuccess) {
        isLicenseLevelValid.value = result.data.level >= licenseLevel.PRO;
      }
    }

    /**
     * Navigates to lead url
     * @param leadUrl
     */
    function navigateToLeadUrl(leadUrl: string) {
      if (!leadUrl) {
        return;
      }
      if (Office.context.ui.openBrowserWindow) {
        Office.context.ui.openBrowserWindow(leadUrl);
      } else {
        window.open(leadUrl);
      }
    }

    /**
     * Runs the search on the server
     **/
    const debounceSearch = debounce(async function () {
      isLoading.value = true;
      if (searchText.value) {
        const result = await leadApi.quicksearch(searchText.value);
        if (result.isSuccess) {
          leadList.value = result.data.items.filter((b: LeadPreview) => !associatedLeadsIds.value.includes(b.id));
        }
      } else {
        leadList.value = [];
      }
      isLoading.value = false;
    }, 500);

    /**
     * Associates current email to selected lead
     * @param leadId
     */
    async function associateCurrentEmailToLead(leadId: string) {
      isLoading.value = true;
      await associateEmailToLead(leadId, Office.context.mailbox.item, associateCurrentEmailToLeadCallback);
    }

    /**
     * Associates current email to selected contact callback
     */
    async function associateCurrentEmailToLeadCallback() {
      await setAssociatedLeads();
      isLoading.value = false;
    }

    /**
     * Sets associated leads ids
     */
    async function setAssociatedLeads() {
      isLoading.value = true;
      if (Office.context.mailbox.item) {
        let result = await emailMessagesApi.getAssociatedLeads(Office.context.mailbox.item.itemId);
        if (result.isSuccess) {
          associatedLeads.value = result.data;
        } else {
          associatedLeads.value = [];
        }
      }
      isLoading.value = false;
    }

    /**
     * Creates new lead
     */
    async function createNewLead() {
      await navigateToLeadEntityCreation(from.value);
    }

    /**
     * Search leads by email
     */
    async function searchLeadsByEmail() {
      leadList.value = [];
      if (!currentMailBoxItem?.from) {
        return;
      }

      isLoading.value = true;

      senderEmail.value = currentMailBoxItem.from.emailAddress;
      const itemEmailAddresses = [currentMailBoxItem.from, ...currentMailBoxItem.to, ...currentMailBoxItem.cc];

      let leadsByEmailResult = await leadApi.getLeadsByEmails(itemEmailAddresses.map((t) => t.emailAddress));
      if (leadsByEmailResult.isSuccess) {
        leadList.value = (leadsByEmailResult.data as LeadPreview[]).filter(
          (b: LeadPreview) => !associatedLeadsIds.value.includes(b.id)
        );
        leadList.value.sort((a, b) => a.LastName?.localeCompare(b.LastName));
      }
      const foundLeadsEmailAddresses = leadList.value.map((c) => c.contactEmailAddress.trim());
      emailAddresses.value = itemEmailAddresses.filter(function (i) {
        return !foundLeadsEmailAddresses.includes(i.emailAddress);
      });

      // Exclude MobiCRM users from contact creation
      let result = await usersApi.getUsers();
      if (result.isSuccess) {
        const usersEmailAddresses = result.data.map((c: any) => c.email);
        emailAddresses.value = emailAddresses.value.filter(function (i) {
          return !usersEmailAddresses.includes(i.emailAddress);
        });
      }
      emailAddresses.value.sort((a, b) => a.displayName.localeCompare(b.displayName));

      isLoading.value = false;
    }

    /**
     * Navigates to lead entity creation
     */
    async function navigateToLeadEntityCreation(emailAddressDetails: Office.EmailAddressDetails) {
      await router.push({
        name: "lead-entity-creation",
        params: {
          emailAddress: emailAddressDetails.emailAddress,
          name: emailAddressDetails.displayName,
          isSender: emailAddressDetails.emailAddress === senderEmail.value,
        },
      });
    }

    return {
      from,
      searchText,
      navigateToLeadUrl,
      isLoading,
      isLicenseLevelValid,
      debounceSearch,
      leadList,
      associatedLeads,
      associateCurrentEmailToLead,
      associatedLeadsIds,
      createNewLead,
      emailAddresses,
      navigateToLeadEntityCreation,
    };
  },
});
